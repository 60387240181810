(function ($) {
    "use strict";

    /* ----- Menu collapse ----- */
    $(window).resize(function () {
        var e = $(this);
        1024 <= e.width() &&
            ($("html").addClass("minimenu"),
            $(".logo-full").hide(),
            $(".logo-abbr").show(),
            $("#menu-mini-button").hide(),
            $("#menu-expend-button").show()),
            1600 < e.width() &&
                ($("html").removeClass("minimenu"),
                $(".logo-full").show(),
                $(".logo-abbr").hide(),
                $("#menu-mini-button").show(),
                $("#menu-expend-button").hide());
    });

    $("#mobile-collapse").on("click", function () {
        $("html").hasClass("nxl-horizontal") || menuclick(),
            $(".nxl-navigation").hasClass("mob-navigation-active")
                ? rmmenu()
                : ($(".nxl-navigation").addClass("mob-navigation-active"),
                  $(".nxl-navigation").append(
                      '<div class="nxl-menu-overlay"></div>'
                  ),
                  $(".nxl-menu-overlay").on("click", function () {
                      rmmenu(), $(".hamburger").removeClass("is-active");
                  }));
    });
    $(".nxl-horizontal #mobile-collapse").on("click", function () {
        $(".topbar").hasClass("mob-navigation-active")
            ? rmmenu()
            : ($(".topbar").addClass("mob-navigation-active"),
              $(".topbar").append('<div class="nxl-menu-overlay"></div>'),
              $(".nxl-menu-overlay").on("click", function () {
                  rmmenu(), $(".hamburger").removeClass("is-active");
              }));
    });
    $(function () {
        $("#menu-mini-button").on("click", function () {
            $("#menu-mini-button").hide(),
                $("#menu-expend-button").show(),
                $("html").addClass("minimenu"),
                localStorage.setItem(
                    "nexel-classic-dashboard-menu-mini-theme",
                    "menu-mini-theme"
                );
        }),
            $("#menu-expend-button").on("click", function () {
                $("#menu-expend-button").hide(),
                    $("#menu-mini-button").show(),
                    $("html").removeClass("minimenu"),
                    localStorage.setItem(
                        "nexel-classic-dashboard-menu-mini-theme",
                        "menu-expend-theme"
                    );
            });
    });

    /* ----- Navbar Scroll To Fixed ----- */
    function navbarScrollfixed() {
        $(".navbar-scrolltofixed").scrollToFixed();
        var summaries = $(".summary");
        summaries.each(function (i) {
            var summary = $(summaries[i]);
            var next = summaries[i + 1];
            summary.scrollToFixed({
                marginTop: $(".navbar-scrolltofixed").outerHeight(true) + 10,
                limit: function () {
                    var limit = 0;
                    if (next) {
                        limit =
                            $(next).offset().top -
                            $(this).outerHeight(true) -
                            10;
                    } else {
                        limit =
                            $(".footer").offset().top -
                            $(this).outerHeight(true) -
                            10;
                    }
                    return limit;
                },
                zIndex: 999,
            });
        });
    }

    /** Main Menu Custom Script Start **/
    $(document).on("ready", function () {
        $("#respMenu").aceResponsiveMenu({
            resizeWidth: "768", // Set the same in Media query
            animationSpeed: "fast", //slow, medium, fast
            accoridonExpAll: false, //Expands all the accordion menu on click
        });
    });

    function mobileNavToggle() {
        if ($("#main-nav-bar .navbar-nav .sub-menu").length) {
            var subMenu = $("#main-nav-bar .navbar-nav .sub-menu");
            subMenu
                .parent("li")
                .children("a")
                .append(function () {
                    return '<button class="sub-nav-toggler"> <span class="sr-only">Toggle navigation</span> <span class="icon-bar"></span> <span class="icon-bar"></span> <span class="icon-bar"></span> </button>';
                });
            var subNavToggler = $("#main-nav-bar .navbar-nav .sub-nav-toggler");
            subNavToggler.on("click", function () {
                var Self = $(this);
                Self.parent().parent().children(".sub-menu").slideToggle();
                return false;
            });
        }
    }

    $(document).ready(function () {
        $("#nxl-lavel-mega-menu-open").on("click", function (e) {
            e.preventDefault(), $("html").addClass("nxl-lavel-mega-menu-open");
        }),
            $("#nxl-lavel-mega-menu-hide").on("click", function (e) {
                e.preventDefault(),
                    $("html").removeClass("nxl-lavel-mega-menu-open");
            });
    });

    /* ----- This code for menu ----- */
    $(window).on("scroll", function () {
        if ($(".scroll-to-top").length) {
            var strickyScrollPos = 100;
            if ($(window).scrollTop() > strickyScrollPos) {
                $(".scroll-to-top").fadeIn(500);
            } else if ($(this).scrollTop() <= strickyScrollPos) {
                $(".scroll-to-top").fadeOut(500);
            }
        }
        if ($(".stricky").length) {
            var headerScrollPos = $(".header-navigation").next().offset().top;
            var stricky = $(".stricky");
            if ($(window).scrollTop() > headerScrollPos) {
                stricky.removeClass("slideIn animated");
                stricky.addClass("stricky-fixed slideInDown animated");
            } else if ($(this).scrollTop() <= headerScrollPos) {
                stricky.removeClass("stricky-fixed slideInDown animated");
                stricky.addClass("slideIn animated");
            }
        }
    });

    /* ----- Blog innerpage sidebar according ----- */
    $(document).on("ready", function () {
        $(".collapse").on("show.bs.collapse", function () {
            $(this).siblings(".card-header").addClass("active");
        });
        $(".collapse").on("hide.bs.collapse", function () {
            $(this).siblings(".card-header").removeClass("active");
        });
    });

    /* ------ Nav Toogle ----- */
    $(document).on("click", ".page-header-right-open-toggle", function (e) {
        e.preventDefault(),
            $(".page-header-right-items")
                .addClass("page-header-right-open")
                .removeClass("page-header-right-close");
    }),
        $(document).on(
            "click",
            ".page-header-right-close-toggle",
            function (e) {
                e.preventDefault(),
                    $(".page-header-right-items")
                        .addClass("page-header-right-close")
                        .removeClass("page-header-right-open");
            }
        );

    /* ----- Tooltips ----- */
    $(document).on("ready", function () {
        const tooltipTriggerList = document.querySelectorAll(
            '[data-bs-toggle="tooltip"]'
        );
        const tooltipList = [tooltipTriggerList].map(
            (tooltipTriggerEl) => new bootstrap.Tooltip(tooltipTriggerEl)
        );
    });

    /* ----- Init ----- */
    $(document).on("ready", function () {
        navbarScrollfixed();
        mobileNavToggle();
    });
})(window.jQuery);

document.addEventListener("DOMContentLoaded", function () {
    var tooltipTriggerList = [].slice.call(
        document.querySelectorAll(
            '[data-bs-toggle="tooltip"], [data-toggle="tooltip"]'
        )
    );
    var tooltipList = tooltipTriggerList.map(function (tooltipTriggerEl) {
        return new bootstrap.Tooltip(tooltipTriggerEl);
    });
});

$(document).ready(function () {
    if ($(".filepond--credits").length) {
        $(".filepond--credits").hide();
    }
});

$(document).ready(function () {
    if ($(".show-password").length) {
        $(".show-password").on("click", function () {
            var passwordField = $(this).siblings("input");
            var passwordFieldType = passwordField.attr("type");
            var icon = $(this).find("i");

            if (passwordFieldType === "password") {
                passwordField.attr("type", "text");
                icon.removeClass("bi-eye").addClass("bi-eye-slash");
            } else {
                passwordField.attr("type", "password");
                icon.removeClass("bi-eye-slash").addClass("bi-eye");
            }
        });
    }
});
