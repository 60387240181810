/*
<--!----------------------------------------------------------------!-->
<--! Select2 Active JS !-->
<--!----------------------------------------------------------------!-->
*/
function iformat(t) {
    var e = t.element;
    return $(
        '<span class="hstack gap-3 ' +
            $(e).data("class") +
            '"><i class="' +
            $(e).data("icon") +
            '"></i> ' +
            t.text +
            "</span>"
    );
}
function bgformat(t) {
    var e = t.element;
    return $(
        '<span class="hstack gap-2"> <span class="wd-7 ht-7 rounded-circle ' +
            $(e).data("bg") +
            '"></span> ' +
            t.text +
            "</span>"
    );
}
function storageformat(t) {
    var e = t.element;
    return $(
        '<span class="hstack gap-3"> <img src="./../assets/images/storage-icons/' +
            $(e).data("storage") +
            '.png" class="avatar-image avatar-sm" /> ' +
            t.text +
            "</span>"
    );
}
function userformat(t) {
    var e = t.element;
    return $(
        '<span class="hstack gap-3"> <img src="./../assets/images/avatar/' +
            $(e).data("user") +
            '.png" class="avatar-image avatar-sm" /> ' +
            t.text +
            "</span>"
    );
}
function paymentformat(t) {
    var e = t.element;
    return $(
        '<span class="hstack gap-3"> <img src="./../assets/images/payment/' +
            $(e).data("payment") +
            '.svg" class="avatar-image avatar-sm" /> ' +
            t.text +
            "</span>"
    );
}
function flagformat(t) {
    var e = t.element;
    return $(
        '<span class="hstack gap-3"> <img src="./../assets/vendors/img/flags/1x1/' +
            $(e).data("flag") +
            '.svg" class="avatar-image avatar-sm" /> ' +
            t.text +
            "</span>"
    );
}
function countryformat(t) {
    var e = t.element;
    return $(
        '<span class="hstack gap-3"> <img src="/images/icons/flags/1x1/' +
            $(e).data("country") +
            '.svg" class="avatar-image avatar-sm" /> ' +
            t.text +
            "</span>"
    );
}
function tzoneformat(t) {
    var e = t.element;
    return $(
        '<span class="hstack gap-3 text-truncate-1-line"> <i class="me-2 ' +
            $(e).data("tzone") +
            '"></i> ' +
            t.text +
            "</span>"
    );
}
function stateformat(t) {
    var e = t.element;
    return $(
        '<span class="hstack gap-3"> <img src="./../assets/vendors/img/flags/us/' +
            $(e).data("state") +
            '.png" class="avatar-image avatar-sm" /> ' +
            t.text +
            "</span>"
    );
}
function cityformat(t) {
    var e = t.element;
    return $(
        '<span class="hstack gap-3"> <span class="wd-7 ht-7 rounded-circle ' +
            $(e).data("city") +
            '"></span> ' +
            t.text +
            "</span>"
    );
}
function languageformat(t) {
    var e = t.element;
    return $(
        '<span class="hstack gap-3"> <span class="wd-7 ht-7 rounded-circle ' +
            $(e).data("language") +
            '"></span> ' +
            t.text +
            "</span>"
    );
}
function currencyformat(t) {
    var e = t.element;
    return $(
        '<span class="hstack gap-3"> <img src="./../assets/vendors/img/flags/1x1/' +
            $(e).data("currency") +
            '.svg" class="avatar-image avatar-sm" /> ' +
            t.text +
            "</span>"
    );
}
function programmingformat(t) {
    var e = t.element;
    return $(
        '<span class="hstack gap-3"> <i class="fa-brand ' +
            $(e).data("programming") +
            '"></i> ' +
            t.text +
            "</span>"
    );
}

$("[data-select2-selector='default']").select2({
    theme: "bootstrap-5",
}),
    $(
        "[data-select2-selector='icon'], [data-select2-selector='gender'], [data-select2-selector='visibility'], [data-select2-selector='privacy'"
    ).select2({
        theme: "bootstrap-5",
        templateResult: iformat,
        templateSelection: iformat,
    }),
    $("[data-select2-selector='storage']").select2({
        theme: "bootstrap-5",
        templateResult: storageformat,
        templateSelection: storageformat,
    }),
    $(
        "[data-select2-selector='tag'], [data-select2-selector='status'], [data-select2-selector='priority'], [data-select2-selector='label'], [data-select2-selector='type']"
    ).select2({
        theme: "bootstrap-5",
        templateResult: bgformat,
        templateSelection: bgformat,
    }),
    $("[data-select2-selector='user']").select2({
        theme: "bootstrap-5",
        templateResult: userformat,
        templateSelection: userformat,
    }),
    $("[data-select2-selector='payment']").select2({
        theme: "bootstrap-5",
        templateResult: paymentformat,
        templateSelection: paymentformat,
    }),
    $("[data-select2-selector='flag']").select2({
        theme: "bootstrap-5",
        templateResult: flagformat,
        templateSelection: flagformat,
    }),
    $("[data-select2-selector='language_id']").select2({
        theme: "bootstrap-5",
        templateResult: countryformat,
        templateSelection: countryformat,
    }),
    $("[data-select2-selector='tzone']").select2({
        theme: "bootstrap-5",
        templateResult: tzoneformat,
        templateSelection: tzoneformat,
    }),
    $("[data-select2-selector='state']").select2({
        theme: "bootstrap-5",
        templateResult: stateformat,
        templateSelection: stateformat,
    }),
    $("[data-select2-selector='city']").select2({
        theme: "bootstrap-5",
        templateResult: cityformat,
        templateSelection: cityformat,
    }),
    $("[data-select2-selector='language']").select2({
        theme: "bootstrap-5",
        templateResult: languageformat,
        templateSelection: languageformat,
    }),
    $("[data-select2-selector='currency']").select2({
        theme: "bootstrap-5",
        templateResult: currencyformat,
        templateSelection: currencyformat,
    }),
    $("[data-select2-selector='programming']").select2({
        theme: "bootstrap-5",
        templateResult: programmingformat,
        templateSelection: programmingformat,
    });
