import '../bootstrap.js';

// Jquery
import jQuery from 'jquery';
window.$ = jQuery;

// Bootstrap
import * as bootstrap from 'bootstrap';
window.bootstrap = bootstrap;

// DataTable
import DataTable from 'datatables.net-dt';
import './plugins/dataTables.bs5.min.js';
window.DataTable = DataTable;

// ProgressBar
import ProgressBar from 'progressbar.js';
window.ProgressBar = ProgressBar;

// Feather
import feather from 'feather-icons';
window.feather = feather;

document.addEventListener('DOMContentLoaded', function () {
    feather.replace();
});

// FullScreenHelper
import FullScreenHelper from 'full-screen-helper';
window.FullScreenHelper = FullScreenHelper;

// sweetalert2
import Swal from 'sweetalert2';
window.Swal = Swal;

// filepond
import FilePond from 'filepond';
window.FilePond = FilePond;

// chart.js
import Chart from 'chart.js/auto';
window.Chart = Chart;


// select2
import Select2 from 'select2';
import './plugins/select2-active.min.js';
window.Select2 = Select2;

// JustGage
import JustGage from 'justgage';
import '../../../node_modules/justgage/raphael.min.js';
window.JustGage = JustGage;


// Calendar
import { Calendar } from '@fullcalendar/core';
window.FullCalendar = Calendar;
import dayGridPlugin from '@fullcalendar/daygrid';
window.dayGridPlugin = dayGridPlugin;
import timeGridPlugin from '@fullcalendar/timegrid';
window.timeGridPlugin = timeGridPlugin;
import interactionPlugin from '@fullcalendar/interaction';
window.interactionPlugin = interactionPlugin;
import bootstrap5Plugin from '@fullcalendar/bootstrap5';
window.bootstrap5Plugin = bootstrap5Plugin;

// lsLStrength
import './plugins/lslstrength.min.js';

// Template
import './theme.js';


