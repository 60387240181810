var DataTable = $.fn.dataTable;

/**
 * DataTables integration for Bootstrap 5.
 *
 * This file sets the defaults and adds options to DataTables to style its
 * controls using Bootstrap. See https://datatables.net/manual/styling/bootstrap
 * for further information.
 */

/* Set the defaults for DataTables initialisation */

/* Default class modification */
$.extend(true, DataTable.ext.classes, {
    container: "dt-container dt-bootstrap5",
    search: {
        input: "form-control form-control-sm",
    },
    length: {
        select: "form-select form-select-sm",
    },
    processing: {
        container: "dt-processing card",
    },
    layout: {
        row: "row mt-2 justify-content-between",
        cell: "d-md-flex justify-content-between align-items-center",
        tableCell: "col-12",
        start: "dt-layout-start col-md-auto me-auto",
        end: "dt-layout-end col-md-auto ms-auto",
        full: "dt-layout-full col-md",
    },
});

/* Bootstrap paging button renderer */
DataTable.ext.renderer.pagingButton.bootstrap = function (
    settings,
    buttonType,
    content,
    active,
    disabled
) {
    var btnClasses = ["dt-paging-button--", "page-item"];

    if (active) {
        btnClasses.push("active");
    }

    if (disabled) {
        btnClasses.push("disabled");
    }

    var li = $("<li>").addClass(btnClasses.join(" "));
    var a = $("<button>", {
        class: "page-link",
        role: "link",
        type: "button",
    })
        .html(content)
        .appendTo(li);

    return {
        display: li,
        clicker: a,
    };
};


DataTable.ext.renderer.pagingContainer.bootstrap = function (
    settings,
    buttonEls
) {
    return $("<ul/>").addClass("pagination").append(buttonEls);
};
